@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'a Atmospheric';
  src: local('Atmospheric'), local('aAtmospheric'),
      url('/fonts/aAtmospheric.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UTM Helve';
  src: local('UTM Helve'), local('UTMHelve'),
      url('/fonts/UTMHelve.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  html,
  body {
    overflow-x: hidden;
  }
  body {
    background-color: black;
    min-width: 320px;
  }
  header {
    position: fixed;
  }
  section {
    width: 100%;
    min-height: 100vh;
  }
  a {
    color: #4287f5;
    text-decoration: none;
  }
}
/* End - @layer base */